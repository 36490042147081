import React, { FC } from 'react'
import Image from 'next/image'
import { Box, Heading, Text, Stack } from '@chakra-ui/react'
import { DateBox } from 'src/uikit'

const Post: FC<PressGlobal.BlogPostItem> = ({
  categories,
  title,
  excerpt,
  image,
  day,
  month,
  href,
}) => {
  const cleanCategories = categories.filter(category => category !== 'Noleggio a Lungo Termine')
  const categoriesList = cleanCategories.join(' | ')
  const descriptionLength = Math.round(288 - (categoriesList.length + (title.length * 1.5)))

  return <a title={title} href={href} target="_blank" rel="noreferrer">
    <Box
      w="full"
      bg="baseWhite"
      rounded="md"
      p={6}
      borderRadius={10}
      overflow="hidden"
      borderColor="#d9dde1"
      borderWidth="1px"
      boxShadow="0 2px 8px 0px rgba(65, 164, 118, 0.12)"
      h="30rem"
    >
      <Box h="12.5rem" mt={-6} mx={-6} pos="relative">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          h="12.5rem"
        >
          <Image
            src={image}
            alt={title}
            layout="fill"
            loading="lazy"
            decoding="async"
          />
          <DateBox
            day={day}
            month={month}
          />
        </Box>
      </Box>
      <Stack marginTop="m" h="calc(100% - 11.3rem);" overflow="hidden">
        <Text
          color="green.500"
          fontFamily="Montserrat-SemiBold"
          fontSize="1rem"
          lineHeight="20.8px"
          letterSpacing="0.2px"
          textAlign="left"
        >
          {categoriesList}
        </Text>
        <Box
          color="baseNeutral.700"
          fontFamily="Montserrat-SemiBold"
          fontSize="0.75rem"
          lineHeight="15.6px"
          letterSpacing="0.4px"
          textAlign="left"
          display="inherit"
        />

        <Heading
          as="h6"
          color="baseDark"
          fontFamily="Montserrat-SemiBold"
          fontSize="1.375rem"
          lineHeight="28.6px"
          letterSpacing="0.57px"
          textAlign="left"
          marginTop="m"
        >
          {title}
        </Heading>
        <Text
          color="baseDark"
          fontFamily="Montserrat-Regular"
          fontSize="1rem"
          lineHeight="20.8px"
          letterSpacing="0.2px"
          textAlign="left"
          marginTop=".562rem"
        >
          {`${excerpt.substring(0, descriptionLength)}...`}
        </Text>
      </Stack>
    </Box>
  </a>
}

export default Post
